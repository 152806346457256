body.dev-mode {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  p {
    position: relative;
    border: 1px solid red;
  }

  h1::after,
  h2::after,
  h3::after,
  h4::after,
  h5::after,
  h6::after,
  p::after,
  span::after,
  div::after {
    content: attr(data-type);
    position: absolute;
    z-index: 9999;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    background-color: #007bff;
    color: white;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  h1::after {
    content: 'h1';
    background-color: #e91e63;
  }

  h2::after {
    content: 'h2';
    background-color: #673ab7;
  }

  h3::after {
    content: 'h3';
    background-color: #2196f3;
  }

  h4::after {
    content: 'h4';
    background-color: #009688;
  }

  h5::after {
    content: 'h5';
    background-color: #ff5722;
  }

  h6::after {
    content: 'h6';
    background-color: #795548;
  }

  p::after {
    content: 'p';
    background-color: #2196f3;
  }

  span::after {
    content: 'span';
    background-color: #2196f3;
  }
}
